import {useForm} from 'react-hook-form';
import {useId} from 'react';
import {User} from '../../../user';
import {Form} from '@common/ui/forms/form';
import {AccountSettingsPanel} from '@common/auth/ui/account-settings/account-settings-panel';
import {FormTextField} from '@common/ui/forms/input-field/text-field/text-field';
import {useUpdateWishes} from './use-wish-list';
import {Button} from '@common/ui/buttons/button';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';
import {FormRadioGroup} from '@common/ui/forms/radio-group/radio-group';
import {FormRadio} from '@common/ui/forms/radio-group/radio';

interface Props {
  user: User;
}

export function WishListPanel({user}: Props) {
  const form = useForm<Partial<Omit<User, 'subscriptions'>>>({
    defaultValues: {
  add_detail_1: user.add_detail_1 || ' ',
  add_detail_2: user.add_detail_2 || ' ',
  add_detail_3: user.add_detail_3 || ' ',
  physical_detail_1: user.physical_detail_1 || ' ',
  physical_detail_2: user.physical_detail_2 || ' ',
  physical_detail_3: user.physical_detail_3 || ' ',
  physical_detail_4: user.physical_detail_4 || ' ',
  physical_detail_5: user.physical_detail_5 || ' ',
  physical_detail_6: user.physical_detail_6 || ' ',
  physical_detail_7: user.physical_detail_7 || ' ',
  physical_detail_8: user.physical_detail_8 || ' ',
  physical_detail_9: user.physical_detail_9 || ' ',
  health_detail_1: user.health_detail_1 || ' ',
  health_detail_2: user.health_detail_2 || ' ',
  health_detail_4: user.health_detail_4 || ' ',
  digital_detail_1: user.digital_detail_1 || ' ',
  digital_detail_2: user.digital_detail_2 || ' ',
  digital_detail_3: user.digital_detail_3 || ' ',
  digital_detail_4: user.digital_detail_4 || ' ',
  digital_detail_5: user.digital_detail_5 || ' ',
  legal_detail_1: user.legal_detail_1 || ' ',
  legal_detail_2: user.legal_detail_2 || ' ',
  legal_detail_3: user.legal_detail_3 || ' ',
  legal_detail_4: user.legal_detail_4 || ' ',
  legal_detail_5: user.legal_detail_5 || ' ',
  legal_detail_6: user.legal_detail_6 || ' ',
  legal_detail_7: user.legal_detail_7 || ' ',
  financial_detail_1: user.financial_detail_1 || ' ',
  financial_detail_2: user.financial_detail_2 || ' ',
  financial_detail_3: user.financial_detail_3 || ' ',
  financial_detail_4: user.financial_detail_4 || ' ',
  funeral_detail_1: user.funeral_detail_1 || ' ',
  funeral_detail_2: user.funeral_detail_2 || ' ',
  funeral_detail_3: user.funeral_detail_3 || ' ',
  funeral_detail_4: user.funeral_detail_4 || ' ',
  funeral_detail_5: user.funeral_detail_5 || ' ',
  funeral_detail_6: user.funeral_detail_6 || ' ',
  funeral_detail_7: user.funeral_detail_7 || ' ',
  funeral_detail_8: user.funeral_detail_8 || ' ',
  funeral_detail_9: user.funeral_detail_9 || ' ',
  funeral_detail_10: user.funeral_detail_10 || ' ',
  funeral_detail_11: user.funeral_detail_11 || ' ',
  funeral_detail_12: user.funeral_detail_12 || ' ',
  funeral_detail_13: user.funeral_detail_13 || ' ',
  funeral_detail_14: user.funeral_detail_14 || ' ',
  funeral_detail_15: user.funeral_detail_15 || ' ',
  funeral_detail_16: user.funeral_detail_16 || ' ',
  funeral_detail_17: user.funeral_detail_17 || ' ',
  funeral_detail_18: user.funeral_detail_18 || ' ',
  funeral_detail_19: user.funeral_detail_19 || ' ',
  funeral_detail_20: user.funeral_detail_20 || ' ',
  anything_detail_1: user.anything_detail_1 || ' ',
  anything_detail_2: user.anything_detail_2 || ' ',
    },
  });
  const formId = useId();
  const updateWishes = useUpdateWishes(form);
  return (
    <AccountSettingsPanel
      id={AccountSettingsId.AccountDetails}
      title=""
      actions={
        <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
      }
    >
      <Form
        form={form}
        id={formId}
         onSubmit={newDetails => {
          updateWishes.mutate(newDetails);
        }}
      >
		  
		  <div className="mb-40 text-base text-muted">
			  		<div className="mb-5 text-base text-muted">
            			<Trans message="Your Wishes is an important non-legal document you can create to accompany your will. It allows you to share useful information with your loved ones about issues that fall outside of your will." />
				   </div>
			       <div className="mb-5 text-base text-muted">
			  			<Trans message="IMPORTANT STATEMENT: IF ANY OF THE FOLLOWING CONFLICTS WITH THE CONTENTS OF YOUR WILL THEN THE CONTENTS OF YOUR WILL PREVAILS." />
			  	   </div>
          </div>
		  
		  
          <h1 className="text-3xl mb-20">
            <Trans message="EMERGENCY CONTACTS" />
          </h1>
		  <FormTextField
            className="mb-24"
            name="add_detail_1"
            label={<Trans message="ADD DETAILS OF EMERGENCY CONTACT 1" />}
          />
		  <FormTextField
            className="mb-24"
            name="add_detail_2"
            label={<Trans message="ADD DETAILS OF EMERGENCY CONTACT 2" />}
          />
		  <FormTextField
            className="mb-24"
            name="add_detail_3"
            label={<Trans message="ADD DETAILS OF EMERGENCY CONTACT 3" />}
          />
		   <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
			   <Trans message="Update" />
        </Button>
		  <h1 className="text-3xl mt-20 mb-20">
            <Trans message="LOCATION OF PHYSICAL DOCUMENTS" />
          </h1>
        
          
		  <div className="mb-10 text-base text-muted">
            <Trans message="HEALTH DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_1"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		  <div className="mb-10 text-base text-muted">
            <Trans message="PROPERTY DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_2"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		  <div className="mb-10 text-base text-muted">
            <Trans message="LEGAL DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_3"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		  
		  <div className="mb-10 text-base text-muted">
            <Trans message="VEHICLE DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_4"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		  <div className="mb-10 text-base text-muted">
            <Trans message="BUSINESS DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_5"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		  <div className="mb-10 text-base text-muted">
            <Trans message="FINANCE DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_6"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		  <div className="mb-10 text-base text-muted">
            <Trans message="TAXATION DOCUMENTS" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_7"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		   <div className="mb-10 text-base text-muted">
            <Trans message="SAFE AND DEPOSIT BOXES" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_8"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		   <div className="mb-10 text-base text-muted">
            <Trans message="WILL" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="physical_detail_9"
            label={<Trans message="ADD NOTES ABOUT LOCATION HERE" />}
          />
		   <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
		   <h1 className="text-3xl mt-20 mb-20">
            <Trans message="HEALTH" />
          </h1>
		  <div className="mb-10 text-base text-muted">
            <Trans message="HEALTHCARE DECISIONS" />
	      </div>
		  <div className="mb-10 text-base text-muted">
			<Trans message="If others need to make healthcare decisions for you, what do they need to know?" />
		  </div>
		  <FormTextField
            className="mb-20 mt-10"
            name="health_detail_1"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Make whatever decisions you need to make that are best for you not me" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="If I am on life support with no chance of recovery, switch it off" />
		  </div>
		  
		  <div className="mb-10 text-base text-muted">
            <Trans message="ORGAN DONATION" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="health_detail_2"
            orientation="vertical"
            label={<Trans message="Are you an organ donor?" />}>
            <FormRadio value="yes">
              <Trans message="Yes" />
            </FormRadio>
            <FormRadio value="no">
              <Trans message="No" />
            </FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24  mt-20"
            name="health_detail_4"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  
		  
		
		   <div className="mb-5 text-base text-muted">
			<Trans message="All my passwords are in Privatlocker" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="My donor card is in my wallet" />
		  </div>
		   <div className="mb-20 text-base text-muted">
			<Trans message="You can donate all my organs and body parts" />
		  </div>
		  
		   <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
		  
		   <h1 className="text-3xl mb-20 mt-20">
            <Trans message="DIGITAL" />
          </h1>
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="ACCESS TO DIGITAL ACCOUNTS" />
		  </div>
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="How will your accounts and devices be accessed when you die?" />
		  </div>

		  <FormTextField
            className="mb-24"
            name="digital_detail_1"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="All my passwords are in Privatlocker" />
		  </div>
		  
		  <div className="mb-5 text-base text-muted">
			<Trans message="EMAIL" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="digital_detail_2"
            orientation="vertical"
            label={<Trans message="What do you want to happen to your email histories when you die?" />}>
            <FormRadio value="yes">
              <Trans message="Delete immediately" />
            </FormRadio>
            <FormRadio value="no">
              <Trans message="Grant access only to my executor or first in line only who can delete the histories once my estate is concluded" />
            </FormRadio>
			  <FormRadio value="noo">
              <Trans message="Other" />
            </FormRadio>
          </FormRadioGroup>
		  
		  <FormTextField
            className="mb-24 mt-20"
            name="digital_detail_3"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		   <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="My eldest daughter is to have access to my emails" />
		  </div>
		  
		   <div className="mb-10 text-base text-muted">
            <Trans message="DIGITAL PHOTOS AND FILES" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="What do you want done with your digital photos and files when you die?" />
		  </div>
		  <FormTextField
            className="mb-24 mt-20"
            name="digital_detail_4"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		   <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="All my digital photos should be saved and given to my eldest son" />
		  </div>
		  
		   <div className="mb-10 text-base text-muted">
            <Trans message="SOCIAL MEDIA" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="What do you want done with your social media when you die?" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="digital_detail_5"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		   <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-20 text-base text-muted">
			<Trans message="Delete all my social media accounts" />
		  </div>
		  
		   <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
		  
		   <h1 className="text-3xl mb-20 mt-20">
            <Trans message="LEGAL" />
          </h1>
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="EXECUTOR" />
		  </div>
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="Who is your executor or who should be contacted when managing your estate?" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="legal_detail_1"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="My executor is Ironside who’s address is …" />
		  </div>
		   <div className="mb-20 text-base text-muted">
			<Trans message="My eldest daughter to make all decisions" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="legal_detail_2"
            orientation="vertical"
            label={<Trans message="Do you have a will?" />}>
            <FormRadio value="yes">
              <Trans message="Yes" />
            </FormRadio>
            <FormRadio value="no">
              <Trans message="No" />
     		</FormRadio>
          </FormRadioGroup>
		  
		  <FormTextField
            className="mb-24 mt-20"
            name="legal_detail_3"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="My will is held by Perry Mason, Regent Street, London" />
		  </div>
		  
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="EXPLANATION OF YOUR WILL" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="legal_detail_4"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="Share your reasoning for some of the arrangements you made in your will " />
		  </div>
		  <div className="mb-10 text-base text-muted">
			<Trans message="If you left more of your estate to one of your children" />
		  </div>
		  <div className="mb-10 text-base text-muted">
			<Trans message="Your choice of executor" />
		  </div>
		  
		  <div className="mb-5 mt-20 text-base bold text-muted">
            <Trans message="LIST OF OTHER ASSETS" />
		  </div>
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="List your other assets and heirlooms here" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="legal_detail_5"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  
		  
		  <div className="mb-5 mt-10 text-base bold text-muted">
            <Trans message="PETS" />
		  </div>
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="What should happen to your pets if you can no longer look after them?" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="legal_detail_6"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="My eldest daughter to make all decisions" />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="HOW YOU WOULD LIKE YOUR HEIRS TO SPEND THEIR INHERITANCE" />
		  </div>
		  <FormTextField
            className="mb-24 mt-20"
            name="legal_detail_7"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		   <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
		  
		  <h1 className="text-3xl mb-20 mt-20">
            <Trans message="FINANCIAL" />
          </h1>
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="LIFE INSURANCE" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="financial_detail_1"
            orientation="vertical"
            label={<Trans message="Do you have life insurance?" />}>
            <FormRadio value="yes">
              <Trans message="Yes" />
            </FormRadio>
            <FormRadio value="no">
              <Trans message="No" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="financial_detail_2"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="My life insurance details are in my Privatlocker" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Also check with my employer" />
		  </div>
		  
		  
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="DIGITAL ASSETS" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="What should happen to your digital assets (e.g. crypto) when you die?" />
		  </div>
		  <FormTextField
            className="mb-24 mt-20"
            name="financial_detail_3"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="My eldest daughter to make all decisions" />
		  </div>
		  
		  
		   <div className="mb-10 text-base bold text-muted">
            <Trans message="OTHER FINANCIAL ASSETS" />
		  </div>
		   <div className="mb-10 text-base text-muted">
			<Trans message="What should happen to your other financial assets when you die?" />
		  </div>
		  <FormTextField
            className="mb-24 mt-20"
            name="financial_detail_4"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-20 text-base text-muted">
			<Trans message="My eldest daughter to make all decisions" />
		  </div>
		   <Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
		  
		  <h1 className="text-3xl mb-20 mt-20">
            <Trans message="FUNERAL" />
          </h1>
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="BODY" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_1"
            orientation="vertical"
            label={<Trans message="What do you want to happen to your body after you die?" />}>
            <FormRadio value="Traditional burial">
              <Trans message="Traditional burial" />
            </FormRadio>
            <FormRadio value="Cremation">
              <Trans message="Cremation" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_2"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="I want a traditional service" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Put me in my green dress" />
		  </div>
		  <div className="mb-5 text-base text-muted">
			<Trans message="Spread my ashes over the Ridgeway Café" />
		  </div>
		  
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="SOMEONE SPECIAL" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_3"
            orientation="vertical"
            label={<Trans message="Who do you want to be informed of your funeral?" />}>
            <FormRadio value="All my contacts">
              <Trans message="All my contacts" />
            </FormRadio>
            <FormRadio value="Someone special">
              <Trans message="Someone Special" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_4"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="My funeral contact list is in my Privatlocker" />
		  </div>
		   
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="FUNERAL DETAILS" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_5"
            orientation="vertical"
            label={<Trans message="What details of the funeral are important to you?" />}>
            <FormRadio value="Readings">
              <Trans message="Readings" />
            </FormRadio>
            <FormRadio value="Music, food & drink">
              <Trans message="Music, food & drink" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_6"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Tell my 40th birthday story, play 80s music" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Keep it cheap and simple" />
		  </div>
		 
		  
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="FUNERAL MOOD" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_7"
            orientation="vertical"
            label={<Trans message="What do you want the mood at your funeral to be?" />}>
            <FormRadio value="Somber">
              <Trans message="Somber" />
            </FormRadio>
            <FormRadio value="Celebration">
              <Trans message="Celebration" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_8"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Relaxed, black dress not necessary" />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="FUNERAL LOCATION" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_9"
            orientation="vertical"
            label={<Trans message="Where do you want your funeral to take place?" />}>
            <FormRadio value="Funeral home">
              <Trans message="Funeral home" />
            </FormRadio>
            <FormRadio value="My church">
              <Trans message="My church" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_10"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="I want a traditional Baptist service at St Michael’s church, Michaelson-y-Fedw" />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="FUNERAL FLOWERS" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_11"
            orientation="vertical"
            label={<Trans message="What would you like people to do?" />}>
            <FormRadio value="Flowers">
              <Trans message="Flowers" />
            </FormRadio>
            <FormRadio value="Gifts">
              <Trans message="Gifts" />
     		</FormRadio>
			<FormRadio value="Donations">
              <Trans message="Donations" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_12"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="In lieu of flowers donate to the Salvation Army" />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="MONUMENTS" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_13"
            orientation="vertical"
            label={<Trans message="Do you want anything to be erected in remembrance of you?" />}>
            <FormRadio value="Gravestone">
              <Trans message="Gravestone" />
            </FormRadio>
            <FormRadio value="Bench">
              <Trans message="Bench" />
     		</FormRadio>
			<FormRadio value="Tree">
              <Trans message="Tree" />
     		</FormRadio>
			<FormRadio value="Other">
              <Trans message="Other" />
     		</FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_14"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Locate a bench at the west side of Roath Park Lake" />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="MESSAGES" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_15"
            orientation="vertical"
            label={<Trans message="What messages do you want to pass on when you die?" />}>
            <FormRadio value="I have written a letter">
              <Trans message="I have written a letter" />
            </FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_16"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Find the letter in will section of Privatlocker" />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="OBITUARY" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_17"
            orientation="vertical"
            label={<Trans message="What would you like said at your funeral?" />}>
            <FormRadio value="I have written an obituary">
              <Trans message="I have written an obituary" />
            </FormRadio>
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_18"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="Find my obituary in the will section of Privatlocker " />
		  </div>
		  
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="REMEMBERED FOR" />
		  </div>
		  <FormRadioGroup
            size="sm"
            name="funeral_detail_19"
            orientation="vertical"
            label={<Trans message="What do you want to be remembered for?" />}>
            <FormRadio value="Music">
              <Trans message="Music" />
            </FormRadio>
			<FormRadio value="Poetry">
              <Trans message="Poetry" />
            </FormRadio>
			<FormRadio value="Dedication to my family">
              <Trans message="Dedication to my family" />
            </FormRadio>
			<FormRadio value="My life’s work">
              <Trans message="My life’s work" />
            </FormRadio>
			  <FormRadio value="Other">
              <Trans message="Other" />
            </FormRadio>
			  
          </FormRadioGroup>
		  <FormTextField
            className="mb-24 mt-20"
            name="funeral_detail_20"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		 
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-20 text-base text-muted">
			<Trans message="Here is the story of my life" />
		  </div>
  		<Button
          type="submit"
          variant="flat"
          color="primary"
          form={formId}
          disabled={updateWishes.isPending || !form.formState.isValid}
        >
          <Trans message="Update" />
        </Button>
		  <h1 className="text-3xl mb-20 mt-20">
            <Trans message="ANYTHING ELSE" />
          </h1>
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="LETTERS, DIARIES AND PERSONAL NOTES" />
		  </div>
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="What would you like your loved ones to do with them?" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="anything_detail_1"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  <div className="mb-10 text-base bold text-muted">
            <Trans message="Examples:" />
		  </div>
		   <div className="mb-5 text-base text-muted">
			<Trans message="My secrets are in my Privatlocker Secrets folder" />
		  </div>
		  
		   <div className="mb-5 text-base bold text-muted">
            <Trans message="ANYTHING ELSE" />
		  </div>
		  <div className="mb-5 text-base bold text-muted">
            <Trans message="Is there anything else you wish for?" />
		  </div>
		  <FormTextField
            className="mb-24"
            name="anything_detail_2"
            label={<Trans message="ADD NOTES ABOUT YOUR WISHES HERE" />}
          />
		  
      </Form>
    </AccountSettingsPanel>
  );
}